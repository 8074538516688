import * as actionTypes from "../actions/types";

export default function shoppingCartReducer(state = {}, action) {
    var shoppingcart = { ...state };
    switch (action.type) {
        case actionTypes.FETCH_JSSHOPPING_CART:
            return { ...state, ...action.payload };
        case actionTypes.FETCH_CHANGE_JSSHOPPING_CART:
            return { ...state, ...action.payload };
        case actionTypes.RESET_JSSHOPPING_CART:
            shoppingcart.update = true;
            shoppingcart.added = action.payload;
            shoppingcart.hasError = false;
            shoppingcart.errors = [];
            return { ...state, ...shoppingcart };
        case actionTypes.UPDATE_JSSHOPPING_CART:
            return { ...state, ...action.payload };
        case actionTypes.FETCH_ERROR_JSSHOPPING_CART:
            if(action.payload.hasError) {
                shoppingcart.hasError = action.payload.hasError;
                shoppingcart.errors = action.payload.errors;
                shoppingcart.update = action.payload.update;             
            }
            return { ...state, ...shoppingcart };
        default:
            return state;
    }
}