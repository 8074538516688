import * as actionTypes from "./types";

export const fetchJsShop = () => { 
    return {
        type: actionTypes.FETCH_JSSHOP,
        payload: "jsShop" in window ? window.jsShop : null
    };
};

export const fetchRoutes = () => { 
    const shopRoutes = "jsShop" in window ? window.jsShop.urls.pages : null;
    const customerRoutes = "jsCustomer" in window ? window.jsCustomer.pages : null;
    return {
        type: actionTypes.FETCH_ROUTES,
        payload: {...shopRoutes, ...customerRoutes}
    };
};

export const fetchCategories = () => { 
    return {
        type: 'FETCH_CATEGORIES',
        payload: "jsCategories" in window ? window.jsCategories : null
    };
};

export const fetchJsProducts = () => {    
    return {
        type: actionTypes.FETCH_JSPRODUCTS,
        payload: "jsProducts" in window ? window.jsProducts : null
    };
};

export const fetchOrderHistory = () => { 
    return {
        type: actionTypes.FETCH_JSORDER_HISTORY,
        payload: "jsOrderHistory" in window ? window.jsOrderHistory : null
    };
};

export const fetchShoppingCart = () => { 
    return {
        type: actionTypes.FETCH_JSSHOPPING_CART,
        payload: "jsShoppingCart" in window ? window.jsShoppingCart : null
    };
};

export const fetchShoppingCartChange = cart => {
    return ({
        type: actionTypes.FETCH_CHANGE_JSSHOPPING_CART,
        payload: cart
    });
};

export const fetchResetCart = () => {
    return {
        type: actionTypes.RESET_JSSHOPPING_CART,
        payload: {}
    }
}

export const fetchCheckout = () => { 
    return {
        type: actionTypes.FETCH_CHECKOUT,
        payload: "jsCheckout" in window ? window.jsCheckout : null
    };
};

export const fetchCheckoutProcess = () => { 
    return {
        type: actionTypes.FETCH_CHECKOUT_PROCESS,
        payload: "jsCheckoutProcess" in window ? window.jsCheckoutProcess : []
    };
};

export const fetchForm = () => { 
    return {
        type: actionTypes.FETCH_FORM,
        payload: "jsForm" in window ? window.jsForm : {}
    };
};

export const fetchUpdateForm = (form) => { 
    return {
        type: actionTypes.FETCH_UPDATE_FORM,
        payload: form
    };
};

export const fetchResetForm = () => { 
    return {
        type: actionTypes.FETCH_RESET_FORM,
        payload: []
    };
};

export const fetchNotifications = () => { 
    return {
        type: actionTypes.FETCH_NOTIFICATIONS,
        payload: "jsNotifications" in window ? window.jsNotifications : {}
    };
};

export const fetchResetNotifications = () => { 
    return {
        type: actionTypes.FETCH_RESET_NOTIFICATIONS,
        payload: []
    };
};

export const fetchCustomer = () => { 
    return {
        type: actionTypes.FETCH_CUSTOMER,
        payload: "jsCustomer" in window ? window.jsCustomer : null
    };
};

export function fetchJsCMS() {
    return {
        type: actionTypes.FETCH_JSCMS,
        payload: "jsCms" in window ? window.jsCms : {}
    }
}

export const fetchResetJsCMS = () => {
    return {
        type: actionTypes.FETCH_JSCMS_RESET_CONTENT,
        payload: {}
    }
}
